import React, {useState, useEffect} from 'react';
import { FaBitcoin, FaMoneyBillWave, FaBuilding, FaHome, FaRegCircle, FaTiktok, FaYinYang, FaApple, FaCarrot, FaDrum } from "react-icons/fa"
import { GiChocolateBar, GiPeanut, GiClothJar, GiButter, GiTigerHead, GiFruitBowl, GiChicken } from "react-icons/gi";
import { SiShopee, SiNetflix } from "react-icons/si";
import { FaXTwitter, FaThreads, FaEgg } from "react-icons/fa6";
import { TbBrandDisney } from "react-icons/tb";
import { DiAndroid } from "react-icons/di";

function AkuVsNetizen() {

    const [showAnswer, setShowAnswer] = useState([false,false,false,false,false,false,false,false,false,false,false,false])
    const [answer, setAnswer] = useState(['sayur','Ayam','iPhone','Perodua','Thailand','Netflix','','Shopee','satu','Kaya & Mentega','rumah','1 BTC/hari'])
    
    useEffect(() =>{

        document.title = "Aku vs Netizen - science4derp";
    }, [])

    const toggleAnswer = (element) => {
        setShowAnswer(prevShowAnswer => {
          const newShowAnswer = [...prevShowAnswer]; // Create a copy of the current state
          newShowAnswer[element] = !newShowAnswer[element]; // Toggle the first element
          return newShowAnswer; // Return the new state
        });
    };

    return (
        <div className='sectionsContainer netContainer'>
        <h1 className='interaktifTitle netizenTitle topText'>Aku vs Netizen</h1>

        <div className='container'>
            <div className='row justify-content-center align-items-center'>
                <p className='mb-5 topText h5'>Jom selesaikan kekusutan yang telah lama didebatkan!</p>           
                <p className='netText'>Tomato, kau rasa ia sejenis buah ataupun sayur?</p>
                { showAnswer[0] ? 
                <div id='a0' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum className='iconCol'/> Netizen telah pun bersuara <FaDrum className='iconCol'/></p>
                        <p className='netText'>Tomato adalah sejenis {answer[0]}.</p>
                        <progress className='progressBar' value={0.26}/>
                        <div className='flexD'>
                            <small>Buah (26%)</small>
                            <small>Sayur (74%)</small>
                        </div>
                        <p className='netText'>22,473 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(0)} 
                className='boxContainer row mb-4'
                id='q0'>
                    <div className='box col mx-auto'><GiFruitBowl/> Buah</div>
                    <div className='box col mx-auto'><FaCarrot/> Sayur</div>
                </div>
                }
                <p className='netText'>Yang mana keluar dulu, ayam atau telur?</p>
                { showAnswer[1] ? 
                <div id='a1' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>{answer[1]} keluar dulu.</p>
                        <progress className='progressBar' value={0.81}/>
                        <div className='flexD'>
                            <small>Ayam (81%)</small>
                            <small>Telur (19%)</small>
                        </div>
                        <p className='netText'>21,680 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(1)} 
                className='boxContainer row mb-4'
                id='q1'>
                    <div className='box col mx-auto'><GiChicken/> Ayam</div>
                    <div className='box col mx-auto'><FaEgg/> Telur</div>
                </div>
                }
                <p className='netText'>Phone yang paling best untuk dipakai?</p>
                { showAnswer[2] ? 
                <div id='a2' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>{answer[2]} menang!</p>
                        <progress className='progressBar' value={0.47}/>
                        <div className='flexD'>
                            <small>Android (47%)</small>
                            <small>iPhone (53%)</small>
                        </div>
                        <p className='netText'>20,112 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(2)} 
                className='boxContainer row mb-4'
                id='q2'>
                    <div className='box col mx-auto'><DiAndroid/> Android</div>
                    <div className='box col mx-auto'><FaApple/> iPhone</div>
                </div>
                }
                <p className='netText'>Kereta yang padu?</p>
                { showAnswer[3] ? 
                <div id='a3' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>{answer[3]} lebih macho untuk dipandu!</p>
                        <progress className='progressBar' value={0.33}/>
                        <div className='flexD'>
                            <small>Proton (33%)</small>
                            <small>Perodua (77%)</small>
                        </div>
                        <p className='netText'>20,134 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(3)} 
                className='boxContainer row mb-4'
                id='q3'>
                    <div className='box col mx-auto'><GiTigerHead/> Proton</div>
                    <div className='box col mx-auto'><FaYinYang/> Perodua</div>
                </div>
                }
                <p className='netText'>Bercuti ke utara atau selatan?</p>
                { showAnswer[4] ? 
                <div id='a4' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>{answer[4]} lebih menarik untuk dikunjungi!</p>
                        <progress className='progressBar' value={0.64}/>
                        <div className='flexD'>
                            <small>Thailand (64%)</small>
                            <small>Indonesia (36%)</small>
                        </div>
                        <p className='netText'>20,026 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(4)} 
                className='boxContainer row mb-4'
                id='q4'>
                    <div className='box col mx-auto'>🇹🇭 Thailand</div>
                    <div className='box col mx-auto'>🇮🇩 Indonesia</div>
                </div>
                }
                <p className='netText'>Rancangan TV?</p>
                { showAnswer[5] ? 
                <div id='a5' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>{answer[5]} lebih popular!</p>
                        <progress className='progressBar' value={0.85}/>
                        <div className='flexD'>
                            <small>Netflix (85%)</small>
                            <small>Disney (15%)</small>
                        </div>
                        <p className='netText'>17,232 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(5)} 
                className='boxContainer row mb-4'
                id='q5'>
                    <div className='box col mx-auto'><SiNetflix/> Netflix</div>
                    <div className='box col mx-auto'><TbBrandDisney/> Disney</div>
                </div>
                }
                <p className='netText'>Geng Mas Elon atau Mas Zuckerberg?</p>
                { showAnswer[6] ? 
                <div id='a6' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>X lebih popular!</p>
                        <progress className='progressBar' value={0.68}/>
                        <div className='flexD'>
                            <small>X (68%)</small>
                            <small>Threads (32%)</small>
                        </div>
                        <p className='netText'>19,876 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(6)} 
                className='boxContainer row mb-4'
                id='q6'>
                    <div className='box col mx-auto'><FaXTwitter/> X</div>
                    <div className='box col mx-auto'><FaThreads/> Threads</div>
                </div>
                }
                <p className='netText'>Membeli belah?</p>
                { showAnswer[7] ? 
                <div id='a7' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen tidak dapat menyimpulkan permasalahan <FaDrum/></p>
                        <p className='netText'>Keputusannya adalah seri!</p>
                        <progress className='progressBar' value={0.5}/>
                        <div className='flexD'>
                            <small>Shopee (50%)</small>
                            <small>TikTok Shop (50%)</small>
                        </div>
                        <p className='netText'>19,924 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(7)} 
                className='boxContainer row mb-4'
                id='q7'>
                    <div className='box col mx-auto'><SiShopee/> Shopee</div>
                    <div className='box col mx-auto'><FaTiktok/> TikTok Shop</div>
                </div>
                }
                <p className='netText'>Straw ada _ lubang</p>
                { showAnswer[8] ? 
                <div id='a8' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>Straw ada {answer[8]} lubang je bro!</p>
                        <progress className='progressBar' value={0.78}/>
                        <div className='flexD'>
                            <small>Satu (78%)</small>
                            <small>Dua (22%)</small>
                        </div>
                        <p className='netText'>19,454 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(8)} 
                className='boxContainer row mb-4'
                id='q8'>
                    <div className='box col mx-auto'><FaRegCircle/> Satu</div>
                    <div className='box col mx-auto'><FaRegCircle/><FaRegCircle/> Dua</div>
                </div>
                }
                <p className='netText'>Sapuan roti bakar?</p>
                { showAnswer[9] ? 
                <div id='a9' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>Sapuan {answer[9]} lebih enak dan sedap!</p>
                        <progress className='progressBar' value={0.33}/>
                        <div className='flexD'>
                            <small>Coklat & Kacang (33%)</small>
                            <small>Kaya & Mentega (67%)</small>
                        </div>
                        <p className='netText'>19,246 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(9)} 
                className='boxContainer row mb-4'
                id='q9'>
                    <div className='box col mx-auto'><GiChocolateBar/><GiPeanut/> Coklat & Kacang</div>
                    <div className='box col mx-auto'><GiClothJar/><GiButter/> Kaya & Mentega</div>
                </div>
                }
                <p className='netText'>Kerja di..?</p>
                { showAnswer[10] ? 
                <div id='a10' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>Bekerja di{answer[10]} lebih menyeronokkan!</p>
                        <progress className='progressBar' value={0.9}/>
                        <div className='flexD'>
                            <small>Rumah (90%)</small>
                            <small>Pejabat (10%)</small>
                        </div>
                        <p className='netText'>19,856 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(10)} 
                className='boxContainer row mb-4'
                id='q10'>
                    <div className='box col mx-auto'><FaHome/> Rumah</div>
                    <div className='box col mx-auto'><FaBuilding/> Pejabat</div>
                </div>
                }
                <p className='netText'>Gaji?</p>
                { showAnswer[11] ? 
                <div id='a11' className='boxContainer'>
                    <div className='box boxAns'>
                        <p className='netText'><FaDrum/> Netizen telah pun bersuara <FaDrum/></p>
                        <p className='netText'>Mereka inginkan gaji {answer[11]}.</p>
                        <progress className='progressBar' value={0.65}/>
                        <div className='flexD'>
                            <small>1 BTC/hari (65%)</small>
                            <small>RM1 Juta/bulan (35%)</small>
                        </div>
                        <p className='netText'>17,340 Undian</p>
                    </div>
                </div>
                :
                <div
                onClick={() => toggleAnswer(11)} 
                className='boxContainer row mb-4'
                id='q11'>
                    <div className='box col mx-auto'><FaBitcoin/> 1 BTC/hari</div>
                    <div className='box col mx-auto'><FaMoneyBillWave/> RM1 Juta/bulan</div>
                </div>
                }
                <p className='netText'>Haa cuba tengok, ada tak pilihan kau yang tak sama dengan netizen? jom raikan pendapat yang berbeza, kerana manusia diciptakan dengan pelbagai keunikan.</p>
                <p className='netText'>Dan masing - masing punyai alasan tersendiri dalam setiap pilihan yang mereka tentukan.</p>
            </div>
        </div>
        </div>
    );
}

export default AkuVsNetizen;
